import React from "react";
import { Link } from "react-router-dom";
import LogoSvg from "../Header/Logo.svg";

const NotFoundPage = () => {
  const centerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "77vh", // Make the container take the full viewport height
  };

  return (
    <div style={centerStyle}>
      <Link to="/" ><img src={LogoSvg} alt="Logo" style={{ width: '150px', height: 'auto' }} /></Link>
      <h1>404 - Page Not Found</h1>
      <p>Oops! The page you are looking for does not exist.</p>
      <Link to="/">
        <button className="btn btn-outline-danger">Go Home</button>
      </Link>
    </div>
  );
};

export default NotFoundPage;
