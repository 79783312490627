import React from "react";
import HeroHeading from "./HeroHeading/HeroHeading";
import Products from "./Products/Products";
// import JSONCardsList from "./Cards/JSONCardsList";
// import DBPlayGround from "./DBPlayGround/DBPlayGround";
// import Statistics from "./Statistics/Likes";
import ScrollToTopButton from "./ScrollToTopButton/ScrollToTopButton";

const Home = ({ selectedCountry }) => {

    return (
        <div className="container">
            <HeroHeading selectedCountry={selectedCountry} />
            {/* <Statistics /> */}
            <Products />
            <ScrollToTopButton />
            {/* <DBPlayGround />
            <JSONCardsList /> */}
        </div>
    );
};

export default Home;