import React from "react"; // Import useState and useEffect hooks
import BarcodeLoaderStyles from "./BarcodeLoaderStyles.css";


const BarcodeLoader = () => {
    return (
        <div className={BarcodeLoaderStyles}>

            <div className='spacer'></div>
            <div className='anim-box center spacer'>
                <div></div>
                <div className='scanner'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-lg'></div>
                <div className='anim-item anim-item-lg'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-lg'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-md'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-md'></div>
                <div className='anim-item anim-item-lg'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-lg'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-lg'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-lg'></div>
                <div className='anim-item anim-item-sm'></div>
                <div className='anim-item anim-item-md'></div>
            </div>
            <div className='spacer'></div>
        </div>
    );
};


export default BarcodeLoader;