import React, { useState, useEffect } from "react"; // Import useState and useEffect hooks
import Card from "../Cards/Card"; // Import NewCard component

import db from '../../firebase'; // Import Firebase setup
import { onSnapshot, collection, query, where } from "firebase/firestore"; // Import Firestore functions
import SearchStyles from './SearchStyles.css'; // Import CSS Modules stylesheet as styles
// import LoaderStyles from './LoaderStyles.css'; // Import CSS Modules stylesheet as styles

// import Loader from "./Loaders/RadarLoader";
// import Loader from "./Loaders/CatLoader";
import Loader from "./Loaders/BarcodeLoader";

// import LoaderDiv from "./Loaders/Loader";

// Create CardsList component
const CardsList = () => { // Create CardsList component
  const [products, setProducts] = useState([]); // Create products state
  const [searchQuery, setSearchQuery] = useState(''); // Create searchQuery state
  const [loading, setLoading] = useState(false); // New state for loading indicator
  const [found, setFound] = useState(false); // New state for loading indicator

  // useEffect is a hook that runs when the component mounts
  useEffect(() => {

    // Create a function to fetch the data
    const fetchData = () => {
      let productsCollectionRef = collection(db, 'products_tur'); // Get a reference to the products collection
      setLoading(true); // Set loading to true when fetching data

      if (searchQuery) { // If there's a search query
        // const q = query(productsCollectionRef, where('ProductName', '>=', searchQuery), where('ProductName', '<=', searchQuery + '\uf8ff')); // Create a query to search for the search query
        const q = query(productsCollectionRef, where('code', '==', parseInt(searchQuery))); // 8690793010052 // Create a query to search for the search query
        onSnapshot(q, (snapshot) => { // Listen for changes to the query
          const productList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Get the data from each document

          setProducts(productList); // Set the products state
          setLoading(false); // Set loading to false after data is fetched
          if (productList.length > 0) {
            setFound(true);
          } else {
            setFound(false);
          }

        }); // End onSnapshot
      }
      // else { // If no search query, fetch the first 10 products
      //   const q = query(productsCollectionRef, orderBy('ProductName'), limit(5)); // Create a query to get the first 10 products
      //   onSnapshot(q, (snapshot) => { // Listen for changes to the query
      //     // const productList = snapshot.docs.map(doc => doc.data());
      //     const productList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })); // Include document ID along with data
      //     setProducts(productList); // Set the products state
      //     setLoading(false); // Set loading to false after data is fetched
      //   }); // End onSnapshot
      // } // End if
    }; // End fetchData

    fetchData(); // Call the fetchData function
  }, [searchQuery]); // [db, searchQuery] is the dependency array

  // Handle search query
  const handleSearch = (e) => {  // e is the event object
    setSearchQuery(e.target.value.toLowerCase()); // Set searchQuery state to the value of the input field
  };

  // console.log(products) // Log products to the console

  // Return JSX
  return (
    // Create a container for the search bar and the cards
    <div className={SearchStyles}>
      {/* <LoaderDiv /> */}
      <div className="mb-3">
        <div className="inputbox">
          <input required="required" type="text" value={searchQuery} onChange={handleSearch} />
          <span>Search / بحث / Ara</span>
          <i></i>
        </div>
      </div>

      <div className="py-3 container">
        {loading ? ( // Show loader if loading is true
          // <RadarLoader /> 
          <div className="container loader">
            <Loader />
          </div>
        ) : ( // Show cards if loading is false
          found ? (
            <div className="d-flex justify-content-center flex-wrap gap-3 py-3">
              {products.map((product) => (
                <Card key={product.id} data={product} />
              ))}
            </div>
          ) : (
            <div className="text-center">No product found!</div>
          )

        )}
      </div>

    </div>
  );
};

export default CardsList;