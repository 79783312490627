import React from "react";

const HeroHeading = ({ selectedCountry }) => {
    return (
        <>
            <div className="px-3 py-3 my-3 text-center">
                <h1 className="display-5 fw-bold red-text">Support Palestine, One Boycott at a Time!</h1>
                <div className="col-lg-6 mx-auto">
                    {/* <p className="lead mb-4">
                        Empower Change: Boycott Occupation-Supporting Products!
                        Your Choice Shapes Justice. Stand for Rights, Not Occupation
                    </p> */}
                    <p className="lead">
                        When you do nothing, you stand with falsehood.
                    </p>
                    <p className="lead">
                        .عندما لا تفعل شيئًا ، فأنت تقف مع الباطل
                    </p>
                    <p className="lead">
                        Hiçbir şey yapmayınca batılın yanında duruyorsun.
                    </p>
                </div>
                {/* <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">Find an alternative product in: <small className="display-5 fw-bold red-text">{selectedCountry}</small></p>
                </div> */}

                {/* <div className="col-lg-6 mx-auto">
                    <p className="lead mb-4">
                        "Vote with Your Wallet: Say No to Occupation-Backed Goods!
                        Join the Movement for Justice, One Boycott at a Time!"
                    </p>
                </div> */}
            </div>
        </>
    );
};

export default HeroHeading;