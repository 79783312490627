import React from "react";
import styles from './Footer.css';
import { Link } from "react-router-dom";

// Importing SVGs
import LogoSvg from "./Logo.svg";
import newwindow from "./new_window.svg";

const Footer = () => {
    return (
        <div className={styles}>
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 border-top footer">
                <div className="nav col-md-4 justify-content-start">
                    <p className="mb-0">© 2024, Badil Co</p>
                </div>
                <Link to="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                    <img src={LogoSvg} className="px-3" alt="LinkedinSvg" />
                </Link>
                <ul className="nav col-md-4 justify-content-end">
                    <li className="nav-item"><a href="https://forms.gle/gD16o4t8R9iaZKQa8" className="nav-link px-2 text-body-secondary" target="_blank" rel="noopener noreferrer">Add Project<img src={newwindow} className="px-1" alt="LinkedinSvg" /></a></li>
                    {/* <li className="nav-item"><Link to="/Contact" className="nav-link px-2 text-body-secondary">Contact</Link></li> */}
                    {/* <li className="nav-item"><Link to="/Contribute" className="nav-link px-2 text-body-secondary">Contribute</Link></li> */}
                    <li className="nav-item"><Link to="/AboutUs" className="nav-link px-2 text-body-secondary">About Us</Link></li>
                </ul>
            </footer>
        </div>
    );
};

export default Footer;