import React from 'react';
import styles from './Card.css';
import { Link } from 'react-router-dom';

const Card = (props) => { // Destructure props
    const { data } = props; // Destructure data from props

    return ( // Return JSX
        <Link to={`/ProductDetails/${data.id}`} className={styles} style={{ textDecoration: 'none' }}>
            {/* <div className={styles}> */}
            <article className="product">
                <div className="product-image" style={{ border: data.IsBoycotted ? "4px solid #7c2e2e" : "4px solid #20e95c" }}>
                    <img src={data.ImageLink} alt="No Logo" />
                </div>
                <h2 className="product-name">{data.ProductName}</h2>
                <small style={{ color: data.IsBoycotted ? "#e92063" : "#20e95c" }}>{data.IsBoycotted ? "Supportive" : "Non-supportive"}</small>
                {/* <button className={data.isBoycotted ? "btn btn-outline-danger" : "btn btn-outline-success"}>Open</button> */}
                {/* <Link to={`/ProductDetails/${data.ID}`} className={data.isBoycotted ? "btn btn-outline-danger" : "btn btn-outline-success"}>OPEN</Link> */}
                <div className="product-links">
                    {/* <p className="link">
                        {data.Brand}
                    </p> */}
                    {/* <p className="link">
                        {data.Category[0].toUpperCase() + data.Category.slice(1).toLowerCase()}
                    </p> */}
                    {/* <p className="link">
                        {data.Country}
                    </p> */}
                </div>
            </article>
            {/* </div> */}
        </Link>
    );
};

export default Card;