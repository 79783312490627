import React from "react";
import { Link } from "react-router-dom";
import LogoSvg from "../Header/Logo.svg";

const AboutUs = () => {
  const centerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "77vh", // Make the container take the full viewport height
  };

  return (
    <div>
      <div style={centerStyle}>
        <Link to="/" ><img src={LogoSvg} alt="Logo" style={{ width: '150px', height: 'auto' }} /></Link>
        <h1>Welcome to Badil Co</h1>
        <p>
          Our mission is to curate a diverse range of products that align with ethical practices and support various causes. Through thorough research and community input, we continuously expand our database with alternatives to mainstream brands. You can contribute by suggesting alternative products or identifying brands that don't align with our values. Once verified, these recommendations will be added to our growing collection.
        </p>
        <p>
          مهمتنا هي تنظيم مجموعة متنوعة من المنتجات التي تتوافق مع الممارسات الأخلاقية وتدعم القضايا المختلفة. من خلال البحث الشامل ومدخلات المجتمع، نقوم باستمرار بتوسيع قاعدة بياناتنا ببدائل للعلامات التجارية الرئيسية. يمكنك المساهمة من خلال اقتراح منتجات بديلة أو تحديد العلامات التجارية التي لا تتوافق مع قيمنا. وبمجرد التحقق منها، ستتم إضافة هذه التوصيات إلى مجموعتنا المتنامية.
        </p>
        <p>
          Misyonumuz, etik uygulamalarla uyumlu ve çeşitli amaçları destekleyen geniş bir ürün yelpazesi oluşturmaktır. Kapsamlı araştırma ve topluluk katkısıyla, veri tabanımızı ana akım markalara alternatiflerle sürekli olarak genişletiyoruz. Alternatif ürünler önererek veya değerlerimizle örtüşmeyen markaları belirleyerek katkıda bulunabilirsiniz. Doğrulandıktan sonra bu öneriler büyüyen koleksiyonumuza eklenecektir.
        </p>
        <a href="https://forms.gle/oajvzt9boYpqbApm8" className="btn btn-outline-danger" target="_blank" rel="noopener noreferrer">Sent us a Message</a>
        {/* <Link to="/">
          <button className="btn btn-outline-danger">Go Home</button>
        </Link> */}
      </div>
    </div>
  );
};

export default AboutUs;