import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";

import db from '../../firebase'; // Import Firebase setup
import { collection, doc, getDoc } from "firebase/firestore"; // Import Firestore functions

import Card from "../Cards/Card"; // Import NewCard component


const ProductDetails = () => {
    const { id } = useParams(); // Get the ID from the URL
    const [product, setProduct] = useState(null); // State for the product
    const [alternatives, setAlternatives] = useState([]); // State for the alternatives

    // console.log(id);

    // useEffect is a hook that runs when the component mounts
    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page

        // Fetch the product from Firestore
        const fetchProductById = async () => {
            try { // Try to get the product from Firestore
                const productDocRef = doc(collection(db, 'products_tur'), id); // Get the document reference
                const productSnapshot = await getDoc(productDocRef); // Get the document snapshot

                if (productSnapshot.exists()) { // If the product exists
                    const mainProduct = { id: productSnapshot.id, ...productSnapshot.data() }; // Get the product data
                    setProduct(mainProduct); // Set the product state

                    // Get alternative products
                    const AlternativeIDs = mainProduct.AlternativeIDs.map(id => String(id).trim()); // Get the alternative IDs from the product data and trim whitespace from each ID

                    if (AlternativeIDs && AlternativeIDs.length > 0) { // If there are alternative IDs
                        // console.log('Alternative IDs:', tempalternatives[0]);
                        const alternativesproductPromises = AlternativeIDs.map(async (altid) => { // Map over the alternative IDs and get the product data for each ID
                            console.log('ID:', altid); // Log the ID 
                            const altproductDocRef = doc(collection(db, 'products_tur'), altid); // Get the document reference
                            const altproductSnapshot = await getDoc(altproductDocRef); // Get the document snapshot

                            console.log('Snapshot:', altproductSnapshot); // Log the snapshot

                            if (altproductSnapshot.exists()) { // If the product exists
                                return { id: altproductSnapshot.id, ...altproductSnapshot.data() }; // Return the product data
                            }
                            return null; // If product doesn't exist for this ID return null
                        }); // End map

                        const alternativeProducts = await Promise.all(alternativesproductPromises); // Wait for all promises to resolve
                        console.log('Alternative Products:', alternativeProducts); // Log the alternative products array
                        // Filter out any null values (products that don't exist)
                        const validalternativeProducts = alternativeProducts.filter((product) => product !== null); // Filter out any null values (products that don't exist)

                        setAlternatives(validalternativeProducts); // Assuming setProduct accepts an array of products

                        console.log('Valid Alternative Products:', validalternativeProducts); // Log the valid alternative products array
                    }
                } else {
                    console.log('Product not found!'); // Log an error if the product doesn't exist
                }
            } catch (error) {
                console.error('Error fetching product:', error); // Log an error if there is an error fetching the product
            }
        };
        if (id) { // If there is an ID
            fetchProductById(); // Fetch the product
        }
        return () => {
            // Cleanup function to reset state when component unmounts
            setProduct(null);
            setAlternatives([]);
        };
    }, [id]); // [db, id] is the dependency array, useEffect will run when these values change

    if (!product) { // If there is no product
        return <div>Loading...</div>; // Return a loading message
    } // End if

    // return js
    return (
        <div className="text-center" style={{ paddingTop: `3%` }}>

            <div className="container">
                <div className="row">
                    <div className="col-1">
                        <img src={product.LogoLink ? product.LogoLink : product.ImageLink} className="img-thumbnail" alt="No Logo" style={{ backgroundColor: 'white' }} />
                    </div>
                    <div className="col">
                        {product.IsBoycotted ? (
                            <div className="alert alert-danger" role="alert">
                                <p>Supportive product / منتج داعم / Destekleyici ürün</p>
                                {/* <p>منتج داعم</p>
                                <p>Destekleyici ürün</p> */}
                            </div>
                        ) : (
                            <div className="alert alert-success" role="alert">
                                <p>Non-supportive product / منتج غير داعم / Destekleyici olmayan ürün</p>
                                {/* <p>منتج غير داعم</p>
                                <p>Destekleyici olmayan ürün</p> */}
                            </div>
                        )}
                    </div>
                </div>

                <hr className="red-text border-2" />

                <div className="row">
                    <div className="col-5">
                        <img src={product.ImageLink} className="img-thumbnail" alt="Product" style={{ backgroundColor: 'white' }} />
                    </div>
                    <div className="col">
                        <table className="table table-bordered" style={{ background: '#00ff00' }}>
                            <tbody>
                                <tr>
                                    <th scope="row" className="table-active">Product</th>
                                    <td>{product.ProductName}</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="table-active">Brand</th>
                                    <td>{product.Brand}</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="table-active">About</th>
                                    <td>{product.ShortDesc}</td>
                                </tr>
                                <tr>
                                    <th scope="row" className="table-active">Category</th>
                                    <td colSpan="1">{product.Category}</td>
                                </tr>
                                {/* <tr>
                                    <th scope="row" className="table-active">Country</th>
                                    <td colSpan="1">{product.Country}</td>
                                </tr> */}
                                <tr>
                                    <th scope="row" className="table-active">Proof</th>
                                    {/* <td colSpan="1">{product.Proof}</td> */}
                                    <td colSpan="1">
                                        {product.Proof && Array.isArray(product.Proof) && product.Proof.length > 0 && (
                                            product.Proof.map((link, index) => (
                                                <p key={index}><a href={link} className="link" target="_blank" rel="noreferrer">Link</a></p>
                                            ))
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="table-active">Code</th>
                                    <td colSpan="1">{product.code}</td>
                                </tr>
                                {/* <tr>
                                    <th scope="row" className="table-active">Tags</th>
                                    <td colSpan="1">
                                        {product.Tags.map((tag) => (
                                            <span className="badge text-bg-light mx-1">{tag}</span>
                                        ))
                                        }
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* <div className="row py-2">
                    <div className="col">
                        <div className="alert alert-light" role="alert">
                            {product.Desc}
                        </div>
                    </div>
                </div> */}
            </div>

            <hr className="red-text border-2" />

            <h4 className='red-text'>Alternatives / بدائل / Alternatifler</h4>
            <div className="container">
                <div className="d-flex justify-content-center flex-wrap gap-3 py-3">
                    {alternatives.map((product) => (
                        <Card key={product.id} data={product} />
                    ))}
                </div>
            </div>

            <div className="d-flex justify-content-start py-3">
                <Link to="/" className={`btn btn-outline-danger`}>Go Home</Link>
            </div>
        </div>
    );
};

export default ProductDetails; 