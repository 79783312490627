import React, { useState } from "react"; // import the react package
import { BrowserRouter, Routes, Route } from "react-router-dom"; // import the react-router-dom

import Header from "./components/Header/Header";
import Home from "./components/Home";
import Footer from "./components/Footer/Footer";
import ProductDetails from "./components/ProductDetails/ProductDetails";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import AboutUs from "./components/AboutUs/AboutUs";
// import Brands from "./components/Brands/Brands";
import Brands from "./components/Brands/BrandsJSON";

// create a component
const App = () => {

  // State for the selected country
  const [selectedCountry, setSelectedCountry] = useState('TUR'); // setting the default value of the selected country
  const handleCountryChange = (event) => { // handle the country change
    setSelectedCountry(event.target.value); // set the selected country
  }; // end of handleCountryChange

  return (
    <BrowserRouter>
      <div className="container">
        <Header selectedCountry={selectedCountry} handleCountryChange={handleCountryChange} />
        <Routes>
          <Route path="/" exact element={<Home selectedCountry={selectedCountry} />} />
          <Route path="/ProductDetails/:id" element={<ProductDetails />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Brands" element={<Brands />} />
          {/* Add the NotFoundPage component as the last route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
