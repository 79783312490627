// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCHiL5LvPtLkKGIAUItLVnidSJP007rZmA",
  authDomain: "badildb.firebaseapp.com",
  projectId: "badildb",
  storageBucket: "badildb.appspot.com",
  messagingSenderId: "760829413180",
  appId: "1:760829413180:web:5448cb8b4851be6406c039",
  measurementId: "G-YJ1B8GTT62"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig); // Initialize Firebase

const analytics = getAnalytics(app);

// Get a Firestore instance
const db = getFirestore(app);

export default db; // Get a Firestore instance, it can be seperated from other components