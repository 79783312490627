import React from "react";
import { Link } from "react-router-dom";


// Importing SVGs
import LogoSvg from "./Logo.svg";
import newwindow from "./new_window.svg";

const Header = ({ selectedCountry, handleCountryChange }) => {

    return (
        <div>
            <nav className="navbar py-3">
                <div className="d-flex align-items-center">
                    <Link to="/" ><img src={LogoSvg} className="navbar-brand" alt="Logo" /></Link>
                    <Link to="/" style={{ textDecoration: 'none' }}><span className="red-text fw-bold">BadilCo</span></Link>
                    <Link to="/Brands" style={{ textDecoration: 'none' }}><span className=" fw-bold px-2"> | Brands List</span></Link>
                </div>
                {/* <div>
                    <p>App Level Country State from the header: <strong>{selectedCountry}</strong></p>
                </div> */}

                <form className="d-flex align-items-center">
                    {/* <select className="form-select form-select-sm me-5" aria-label="Default select example" onChange={handleCountryChange} value={selectedCountry}>
                        <option value="TUR">Türkiye</option>
                        <option value="SYR" disabled>Syria</option>
                        <option value="LBN" disabled>Lebanon</option>
                        <option value="IRQ" disabled>Iraq</option>
                        <option value="JOR" disabled>Jordan</option>
                        <option value="EGY" disabled>Egypt</option>
                    </select> */}
                    <a href="https://forms.gle/gD16o4t8R9iaZKQa8" className="btn btn-outline-danger" target="_blank" rel="noopener noreferrer">Add <img src={newwindow} className="px-1" alt="LinkedinSvg" /></a>
                </form>

            </nav>
            <div className="py-1">
                <hr className="hr-nav" />
                <br />
                <hr className="hr-nav mx-1" />
            </div>
        </div>

    );
};

export default Header;