import React, { useState, useEffect } from "react";
import Card from "../Cards/Card";
// import Search from "../Search/Search";

import BrandsData from "./BrandsData.json";

const JSONCardsList = () => {

  const [searchText, setSearchText] = useState("");
  const [filteredBrands, setFilteredBrands] = useState(BrandsData);

  const handleSearch = (event) => {
    const value = event.target.value;
    console.log("Search value:", value);
    setSearchText(value);
  };

  useEffect(() => {
    if (searchText === "") {
      setFilteredBrands(BrandsData);
    } else {
      const filtered = BrandsData.filter((product) =>
        product.BrandName.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredBrands(filtered);
    }
  }, [searchText]);

  return (
    <div className="py-3 container">

      {/* Search bar */}
      <div className="mb-5 mt-3">
        <div className="inputbox">
          <input required="required" type="text" value={searchText} onChange={handleSearch} />
          <span>Search / بحث / Ara</span>
          <i></i>
        </div>
      </div>

      {/* <div className="d-flex justify-content-center flex-wrap gap-3 py-3">
        {filteredBrands.map((product) => (
          <Card data={product} />
        ))}
      </div> */}

      <table className='table table-bordered mt-4'>
        <thead>
          <tr>
            <th className="col">#</th>
            <th className="col-1">Logo</th>
            <th className="col-1">Name</th>
            <th className="col-1">Parent</th>
            <th className="col-2">About</th>
            <th>Description</th>
            <th className="col">Proof</th>
          </tr>
        </thead>
        <tbody>
          {filteredBrands.map((brand) => (
            <tr key={brand.BrandID}>
              <td>{brand.BrandID}</td>
              <td>
                <img src={brand.LogoLink} alt={brand.BrandName} style={{ width: '150px', backgroundColor: 'white' }} />
              </td>
              <td>{brand.BrandName}</td>
              <td>{brand.ParentBrand}</td>
              <td>{brand.About}</td>
              <td>{brand.Description}</td>
              <td>
              <p key={brand.Proof}><a href={brand.Proof} className="link" target="_blank" rel="noreferrer">Link</a></p>
                {/* {brand.Proof && Array.isArray(brand.Proof) && brand.Proof.length > 0 && (
                  brand.Proof.map((link, index) => (
                    <p key={index}><a href={link} className="link" target="_blank" rel="noreferrer">Link</a></p>
                  ))
                )} */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>


    </div>
  );
};

export default JSONCardsList;